(function (factory) {
    'use strict';

    if (typeof define === 'function' && define.amd) {
        define([
            'jquery',
            './validator',
            './messages'
        ], factory);
    } else {
        factory(jQuery);
    }
}(function (
    $
) {
    'use strict';

    var $form = $('#form-contact');

    $form.validate();

    $form.on('submit', function (e) {
        e.preventDefault();

        $form.hideMessages();


        // $form.showMessages([
        //     {
        //         'text': 'test',
        //         'type': 'error'
        //     }
        // ]);

        if (!$form.valid()) {
            return;
        }

        $('.vdbroek-loading').show();

        if (!$form.attr('data-validated')) {
            grecaptcha.ready(function () {
                grecaptcha.execute($form.data('captcha-site-key'), {action: 'contact_footer'}).then(function (token) {
                    $('input#g-recaptcha-response').remove();
                    $('input#g-recaptcha-action').remove();
                    $form.prepend('<input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" value="' + token + '">');
                    $form.prepend('<input type="hidden" id="g-recaptcha-action" name="g-recaptcha-action" value="contact_footer">');
                    $form.attr('data-validated', true).submit().removeAttr('data-validated');
                });
            });
            e.preventDefault(e);
            e.stopImmediatePropagation();

            return;
        }

        $.ajax({
            url: $form.attr('action'),
            data: $form.serialize(),
            cache: false,
            showLoader: false,
            global: false,
            type: 'POST',
            dataType: 'json',
            success: function (response) {
                if (response.messages && response.messages.length && response.messages[0].type === 'error') {
                    $form.showMessages(response.messages);
                    $('.vdbroek-loading').hide();
                } else {
                    $form.showMessages(response.messages);
                    $form.find('[data-role="form-wrapper"]').hide();
                    $('.vdbroek-loading').hide();
                }
            },
            error: function (xhr, statusText, errThrown) {
                console.log(xhr);
                $('.vdbroek-loading').hide();
                alert('<div class="infoBox">Er is een onbekende fout opgetreden.</div>');
            }
        });
    });
}));
