(function (factory) {
    'use strict';

    if (typeof define === 'function' && define.amd) {
        define([
            'jquery'
        ], factory);
    } else {
        factory(jQuery);
    }
}(function (
    $
) {

    $.fn.parseMessage = function (message) {
        if (typeof message === 'string' || typeof message.parameters === 'undefined') {
            return message.message || message.text;
        }
        var expr = /([%])\w+/g,
            messageText = message.message || message.text;

        return messageText.replace(expr, function (varName) {
            varName = varName.substr(1);

            if (message.parameters.hasOwnProperty(varName)) {
                return message.parameters[varName];
            }

            return message.parameters.shift();
        });
    };

    $.fn.formatMessages = function (messages) {
        var messagesFormatted = '';
        $.each(messages, function (i, message) {
            switch (message.type) {
                case 'error':
                    messagesFormatted += '<div class="alertBox">' + $.fn.parseMessage(message) + '</div>';
                    break;
                case 'success':
                    messagesFormatted += '<div class="successBox">' + $.fn.parseMessage(message) + '</div>';
                    break;
                default:
                    messagesFormatted += '<div class="infoBox">' + $.fn.parseMessage(message) + '</div>';
                    break;
            }
        });

        return messagesFormatted;
    };

    $.fn.showMessages = function (messages, method, target) {

        var $messages = $(target || this).find('[data-role="message-container"]').length
            ? $(target || this).find('[data-role="message-container"]')
            : $('<div data-role="message-container" style="width: 100%;"/>').prependTo(this || target),
            jqueryMethod = method || 'append';

        $messages[jqueryMethod]($.fn.formatMessages(messages)).show();
    };

    $.fn.hideMessages = function (target) {

        $(target || this).find('[data-role="message-container"]').html('').hide();
    };

}));
