(function (factory) {
    'use strict';

    if (typeof define === 'function' && define.amd) {
        define([
            'jquery',
            './jquery.validate'
        ], factory);
    } else {
        factory(jQuery);
    }
}(function (
    $,
    validator
) {
    'use strict';

    $.validator.setDefaults({
        errorElement: 'span',
        errorClass: "is-invalid",
        pendingClass: "is-pending",
        validClass: "is-valid",
        // errorPlacement: function (error, element) {
        //     error.addClass('invalid-feedback');
        //     element.closest('.form-group').append(error);
        // },
        // highlight: function (element, errorClass, validClass) {
        //     $(element).removeClass('is-valid');
        //     $(element).addClass('is-invalid');
        // },
        // unhighlight: function (element, errorClass, validClass) {
        //     $(element).removeClass('is-invalid');
        //     $(element).addClass('is-valid');
        // }
    });

    $.extend($.validator, validator);

}));
